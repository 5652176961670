import React from 'react';
import Container from '@mui/joy/Container';
import Button from '@mui/joy/Button';
import { Grid } from '@mui/joy';
import WelcomeHeading from '@/components/reusable/WelcomeHeading';
import OpenInAppModal from '@/components/signin/OpenInAppModal';
import { Box } from '@mui/system';

export const LoginInProgress = ({ onClick, setHandleLoginLinkInBrowser, children }) => {
  return (
    <Container
      sx={{
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <WelcomeHeading />
          {children}
          <Box sx={{ mt: 4 }}>
            <Button variant={'outlined'} size="sm" color="primary" onClick={onClick}>
              Go Back
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
